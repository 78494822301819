<template>
    <div class="main-container">
        <Top ref="topRef"></Top>
        <SlideBar ref="slidbarRef"></SlideBar>
        <div :class="contentClass" ref="mainContentRef">
            <Tabs></Tabs>
            <ContentArea ref="contentRef"></ContentArea>
        </div>
    </div>
</template>
<script>
    import Top from "@/components/layout/Top";
    import SlideBar from "@/components/layout/SlideBar";
    import ContentArea from "@/components/layout/ContentArea";
    import Tabs from "@/components/layout/Tabs";

    import {useStore} from "vuex";
    import {ref, reactive, toRefs, onMounted, onActivated, computed, watch, getCurrentInstance, defineComponent, nextTick, provide} from 'vue';
    export default defineComponent({
        name: "Main",
        setup(){
            const topRef=ref(null);
            const slidbarRef=ref(null);
            const mainContentRef=ref(null);
            const contentRef=ref(null);
            const {proxy}=getCurrentInstance();
            provide('mainComp', proxy);
            const dataObj=reactive({
                contentClassFlag:false
            })
            let store = useStore();

            onActivated(async()=>{

            })
            onMounted(async ()=>{
                //刚刚进入main的时候，跳转到welcome这个路由
                await proxy.$router.push({path: "/welcome"});
                await store.dispatch("closeAllVisitedTabsView");//关闭所有tabs，只保留/welcome
                await nextTick(async () => {

                });
            })
            //---------------------------computed------------
            const contentClass=computed(()=>{//根据是否折叠左边菜单，动态计算右边区域大小
                return {
                    main_content_container_small: !dataObj.contentClassFlag,
                    main_content_container_big: dataObj.contentClassFlag
                }
            })
            return{
                ...toRefs(dataObj),topRef,slidbarRef,mainContentRef,contentRef,
                contentClass
            }
        },

        components: {
            Top,
            SlideBar,
            ContentArea,
            Tabs
        }
    });
</script>
<style scoped>
    .main-container {
        width: 100%;
        height: 100%;
    }

    /*
    由于top和SlideBar都采用了fixed定位，脱离了文档流，所以我们的content需要向上和向左让出对应的距离，让出一定距离之后，宽度也随之减少相应的距离
    */
    .main-container .main_content_container_small {
        width: calc(100% - 201px);
        height: calc(100% - 56px); /*top的高度56px*/
        padding-left: 201px;
        padding-top: 56px; /*top的高度56px*/
    }
    /**
    折叠之后，工具栏的宽度为64px
    */
    .main-container .main_content_container_big {
        width: calc(100% - 64px);
        height: calc(100% - 56px); /*top的高度56px*/
        padding-left: 64px;
        padding-top: 56px; /*top的高度56px*/
    }
</style> 