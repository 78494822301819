<template>
    <div class="tabs-container">
        <div class="leftTab">
            <el-scrollbar>
                <router-link class="tags-view-item" :class="isActive(tag) ? 'active' : '' " v-for="(tag, index) in visitedTabsView" :to="tag.path" :key="index">
                    <el-tag closable @close.prevent.stop="closeHandler(tag)">
                        {{tag.title}}<i class="el-icon-refresh" id="refresh" @click.prevent.stop="refreshHandler(tag)" title="刷新"/>
                    </el-tag>
                </router-link>
            </el-scrollbar>
        </div>
        <div class="rightTab">
            <el-dropdown @command="rightTabCommandHandler">
                <el-button type="primary">
                    更多
                    <i class="el-icon-arrow-down el-icon--right"/>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item command="other" icon="iconfont icon-qita">关闭其它</el-dropdown-item>
                        <el-dropdown-item command="all" icon="iconfont icon-suoyou">关闭所有</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
    </div>

</template>

<script>
    import {mapActions,useStore} from "vuex";
    import {getCurrentInstance, onMounted, watch,defineComponent} from "vue";
    import {useRoute} from "vue-router";

    export default defineComponent({
        name: "Tabs",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            const route = useRoute();//当前路由对象
            let store = useStore();
            let visitedTabsView = utils.$$tools.getPropFromVuex('visitedTabsView');
            // let mutations=mapMutations(['CHECK_TABSVIEW_EXIST']);
            // let existTab = mutations.CHECK_TABSVIEW_EXIST.bind({ $store: store });
            let actions=mapActions(["checkTabsViewExist","addVisitedTabsView","closeSpeTabsView","closeAllVisitedTabsView","closeOtherTabsView"]);
            let checkTabsViewExist = actions.checkTabsViewExist.bind({ $store: store });
            let addVisitedTabsView = actions.addVisitedTabsView.bind({ $store: store });
            let closeSpeTabsView = actions.closeSpeTabsView.bind({ $store: store });
            let closeAllVisitedTabsView = actions.closeAllVisitedTabsView.bind({ $store: store });
            let closeOtherTabsView = actions.closeOtherTabsView.bind({ $store: store });

            onMounted(()=>{})
            //右侧下拉command组件事件
            const rightTabCommandHandler=(command)=>{
                if ("other" == command) {//关闭其它tabs
                    let currentView = {title: route.meta.title, path: route.path}
                    closeOtherTabsView(currentView);
                } else if ("all" == command) {//关闭所有tabs
                    closeAllVisitedTabsView();
                    proxy.$router.push("/welcome");
                }
            }

            //判断传入的路由对象是否是当前路由，高亮显示当前路由的时候用
            const isActive=(routeT)=>{
                return routeT.path === route.path || routeT.title === route.meta.title;
            }
            //关闭tab事件
            const closeHandler=(tag)=>{
                closeSpeTabsView(tag).then(visitedTabsView => {//如果删除的不是/welcome才会执行回调，否则不会执行回调
                    // 如果关闭的是当前显示的页面，就去到前一个 tab-view 页面
                    if (isActive(tag)) {
                        const lastTag = visitedTabsView.slice(-1)[0];
                        // 前一个 tab-view 页面存在，就跳；不存在就到首页
                        if (lastTag) {
                            proxy.$router.replace(lastTag.path);
                        } else {
                            proxy.$router.replace("/");
                        }
                    }
                });
            }
            //刷新tab事件
            const refreshHandler=(tag)=>{
                if (tag.path == proxy.$router.currentRoute.value.path){//如果刷新的是当前路由，则重新加载一下
                    proxy.$parent.contentRef.reload()
                } else {//如果刷新的不是当前路由，则直接跳转到要刷新的路由即可
                    proxy.$router.replace(tag.path);
                }
            }
            //往已访问的tab数组中添加路由
            const addTabsView=()=>{
                //checkTabsViewExist是vuex的action，返回的是一个promise，所以需要在resolve里面写
                checkTabsViewExist(route).then(exist=>{
                    //最后想想没有用到exist，因为addVisitedTabsView的时候判断过是否已经存在，存在是不会往数组中添加的
                    addVisitedTabsView(route);
                })
            }
            watch(() => route.path,() => {
                    //在tabs里面监控路由，只要路由变化就把新的路由添加到我们的已访问路由数组中,如果是以顶部菜单显示，还需要高亮显示顶部菜单
                    addTabsView();
                }
            )
            return{
                visitedTabsView,checkTabsViewExist,addVisitedTabsView,closeSpeTabsView,closeAllVisitedTabsView,closeOtherTabsView,isActive,
                rightTabCommandHandler,addTabsView,closeHandler,refreshHandler
            }
        },
    });
</script>

<style scoped>
    .tabs-container ::v-deep(.el-scrollbar .el-scrollbar__wrap .el-scrollbar__view){
        white-space: nowrap;
        overflow-x: auto;
    }
    .tabs-container {
        width: 100%;
        height: 35px;
        /*padding: 5px 5px 0px 5px;*/
        border-bottom: 1px solid #dfdfdf;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .leftTab{
        flex: 1;
        overflow-x: auto;
    }
    .rightTab {
        margin-right: 0px;
    }

    .tabs-container .tags-view-item .el-tag {
        margin: 0 3px;
    }

    .active .el-tag {
        background-color: #00b4aa;
        color: #fff;
    }

    .el-tag .el-icon-close {
        color: #fff !important;
    }

    .active .el-tag::before {
        position: relative;
        content: "";
        /*不删掉的话，在激活的tag中，前面会有一个白色的小圆点*/
        /*background: #fff;*/
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 2px;
    }

    .active #refresh {
        /*color: #409EFF;*/
        color: #fff;
    }

    #refresh:hover {
        background-color: #409eff;
        border-radius: 50%;
        color: #fff;
        font-size: 14px;
    }

    .active #refresh:hover {
        color: #fff;
        font-size: 14px;
    }
</style>
 